import '@stylesheets/page-loading.scss'

(function () {
  window.onload = function () {
    const preloader = document.querySelector('.page-loading')
    preloader.classList.remove('active')
    setTimeout(function () {
      preloader.remove()
    }, 2000)
  }
})()
